.loginpg .web-authform-container {
    width: 33%;
}
.loginpg-header {
    padding-bottom: 4%;
}

@media screen and (max-width: 576px){
    .loginpg .web-authform-container {
        width: 85%;
    }
}
@media screen and (max-width: 768px){
    .loginpg .web-authform-container {
        width: 75%;
    }
}
@media screen and (max-width: 996px){
    .loginpg .web-authform-container {
        width: 65%;
    }
}
@media screen and (min-width: 1200px){
    .loginpg .web-authform-container {
        width: 33%;
    }
}





