@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /*Font CSSProps*/
  --fontFamily: "Kanit", sans-serif;

  --webPrimary: #27cf72; /*efc949*/
  --webPrimary-dark: #139f51; /*ef9700*/
  --webPrimary-light: #07bb57; /*d7d300;*/

  --clrGray: #3e3e3e;
  --clrGray-light: #5c5a5a;
  --clrGray-light-p1: #959393;

  --clrWhite: #ffffff;
  --clrWhite-dark: #ddd;
}

@import "assets/buttons.css";

p {
  line-height: 1.8;
  font-family: var(--fontFamily);
  color: var(--clrGray-light);
}
h1 {
  font-family: var(--fontFamily);
  color: var(--clrGray);
}
h2 {
  font-family: var(--fontFamily);
  color: var(--clrGray-light);
}
h4 {
  font-family: var(--fontFamily);
  color: #959393;
  border-bottom: 1px dashed #959393;
  display: inline;
}
/*******************************************************************/
.web-authform-container {
  width: 65%;
  min-height: 10rem;
  height: auto;
  padding: 3%;
  background: #fff;
  box-shadow: rgb(149 157 165 / 42%) 0px 8px 24px;
  border-radius: 25px;
}
.web-authform-right {
  width: 100%;
  height: 100vh;
  background: #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*******************************************************************/
.app-container {
  background: #fff;
  border: 1px solid #e5e5e5;
  padding: 3%;
}
.horizontal-pd-2{
  padding-left: 2%;
  padding-right: 2%;
}
.vertical-pd-2{
  padding-top: 2%;
  padding-bottom: 2%;
}
.web-layout {
  background: var(--clrWhite);
}
.web-sections {
  margin: 3% 0;
  padding: 0 4%;
}
.web-sections-box {
  margin: 2% 0%;
}
.web-container {
  margin: 2% 4%;
  padding: 2%;
  box-shadow: rgb(149 157 165 / 42%) 0px 8px 24px;
  border-radius: 25px;
  font-family: var(--fontFamily);
}
.web-lable {
  font-weight: 600;
  font-family: var(--fontFamily);
  color: var(--clrGray-light);
  font-size: 15px;
}

