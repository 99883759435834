.web-primary-btn {
    background: var(--webPrimary);
    color: var(--clrGray);
    border: none;
    font-size: 11pt;
    font-weight: 700;
    height: 36px;
    font-family: var(--fontFamily);
}
.web-primary-btn:hover,
.web-primary-btn:focus,
.web-primary-btn:active {
    background: var(--webPrimary-dark);
    color: #000000 !important;
}
