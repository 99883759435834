.Admin-menu.ant-menu .ant-menu-dark,
.Admin-menu.ant-menu-dark .ant-menu-item,
.Admin-menu.ant-menu-dark .ant-menu-item-group-title,
.Admin-menu.ant-menu-dark .ant-menu-item > a,
.Admin-menu.ant-menu-dark .ant-menu-item > span > a,
.Admin-menu.ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon,
.Admin-menu.ant-menu.ant-menu-inline-collapsed .anticon,
.ant-menu.ant-menu-dark {
    color: rgb(255 255 255) !important;
}
.Admin-menu .ant-menu-item {
    border-radius: 10px;
    background: var(--Adminsecondary) !important;
}
.Admin-menu .ant-menu-item-active, .Admin-menu .ant-menu-item-selected {
    background: var(--Adminprimarydark) !important;
}
.Admin-menu .ant-menu-item .ant-menu-item-icon, .ant-menu.ant-menu-dark {
    border-right: 1px solid #ffffff42;
    padding-right: 5%;
}
.Admin-menu .ant-menu.ant-menu-inline-collapsed .ant-menu-item-icon, .ant-menu.ant-menu-inline-collapsed .anticon {
    border: none;
}

.ant-layout-sider {
    background: var(--webPrimary-dark) !important;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background: var(--webPrimary-light);
}

.ant-menu-submenu-title .ant-menu-item-icon, .ant-menu-submenu-title .anticon {
    min-width: 14px;
    font-size: 14px;
    transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
    border-right: 1px solid #ffffff42;
    padding-right: 5%;
}